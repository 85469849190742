
.filter {
  &-container {
    padding: 15px 0;
    width: 100%;
  }

  &-row {
    width: 95%;
    margin: 0 auto;
  }

  &-btn-group {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

    .btn:first-child {
      margin-right: 5%;
    }
  }

  &-objects {
    margin: 10px 0;

    .list-group {
      &-item {
        border: none;
        padding: 5px 0;

        .form-group {
          align-items: center;
          font-size: 12px;
          margin-bottom: 0;

          .btn-danger {
            min-width: 38px;
          }

          .form-control {
            font-size: 12px;
          }
        }

      }
    }
  }
}

.filter-row {
  * {
    font-size: 14px;
  }

  .filter-header {
    font-weight: 900;
    p {
      margin-bottom: 0;
    }
  }
}