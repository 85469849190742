

.event-modal {
  .modal-header {
    padding: 16px 35px;
  }

  .modal-title {
    width: 90%
  }

  .task {
    max-height: unset;
    border: none;
    background: none;
    box-shadow: none;

    .event-date {
      border: none;

      &-header {
        h6 {
          font-weight: 900;
        }
      }

      &-info {
        & > div {
          display: flex;
          align-items: center;
        }

        time, p {
          font-size: 12px;
          font-weight: 700;
          color: #000;
          padding: 0;
          margin: 0;
        }

        &-header {
          border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  .modal-footer {
    button {
      border-radius: 50px;
      font-size: 14px;
    }
  }
}