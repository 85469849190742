@import "src/assets/scss/base";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.Register {
  .fusion-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    //align-items: center;
    background: transparent;

    form {
      min-width: 480px;

      @include media-breakpoint-down(md) {
        min-width: 1px;
      }
    }

    .login-container {
      margin: 0 15%;
      //border-radius: 8px;
      //padding: 65px 35px;
      //box-shadow: 0 10px 10px rgba(0,0,0,0.5);
      //border: 1px solid rgba($primary, 0.1);
      //min-width: 410px;
      //min-height: 300px;
      //display: flex;
      //flex-direction: column;
      //justify-content: center;
      //align-items: center;
      //background: #fff;
      &.register-form {
        margin: 0 25%;
      }
    }
  }
  &.RegisterWide {
    .fusion-container {
      margin: 50px auto;
      padding-top: 45px;
      padding-bottom: 45px;
      max-width: 1360px;
      width: 100%;

      .image-holder {
        max-width: 500px;
      }

      form {
        width: 100%;
      }
    }
  }

  label {
    &.required {
      &:after {
        content: '*'
      }
    }
  }
}
