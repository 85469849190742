@import "../../assets/scss/base";

.search {
  &-container {
    width: 100%;

    * {
      font-size: 14px;
    }
  }

  &-field {
    margin: 0 auto;
    color: $primaryText;
    position: relative;

    input[type="text"] {
      font-size: 14px;
      font-weight: bold;
      padding: 8px 10px;
      border-radius: 5px;
      color: $primaryText;
      border-color: $grey;
      border: 1px solid $grey;
      border-right: none !important;
      min-height: 45px;
    }

    .input-group-append {
      .btn {
        background: transparent;
        border-radius: 5px;
        color: $primaryText;
        border-color: $grey;
        border-left: none !important;
      }
    }
  }

  &-autocomplete {
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 9999;
    width: 100%;
  }
}
