
@mixin get_border_status($before: false){
  &.SECTION_CREATED, &.SECTION_OPENED {
    @if($before) {
      &:before {
        @include get_status_border_color($created)
      }
    } @else {
      @include get_status_border_color($created)

    }
  }

  /*&.active {
    @if($before) {
      &:before {
        @include get_status_border_color($active)
      }
    } @else {
      @include get_status_border_color($active)

    }
  }*/

  &.SECTION_CANDIDATE_AVAILABLE {
    @if($before) {
      &:before {
        @include get_status_border_color($candidature, true)
      }
    } @else {
      @include get_status_border_color($candidature, true)
    }
  }

  &.not_confirmed {
    @if($before) {
      &:before {
        @include get_status_border_color($not_confirmed)

      }
    } @else {
      @include get_status_border_color($not_confirmed)

    }
  }

  &.SECTION_OPERATIONAL,
  &.SECTION_NOT_ALL_CONFIRMED {
    @if($before) {
      &:before {
        @include get_status_border_color($operational)

      }
    } @else {
      @include get_status_border_color($operational)

    }
  }

  &.not_apply {
    @if($before) {
      &:before {
      @include get_status_border_color($not_apply, true)
      }
    } @else {
    @include get_status_border_color($not_apply, true)
    }
  }

  &.apply {
    @if($before) {
      &:before {
        @include get_status_border_color($apply)
      }
    } @else {
      @include get_status_border_color($apply)
    }
  }

  &.reject {
    @if($before) {
      &:before {
      @include get_status_border_color($reject)
      }
    } @else {
    @include get_status_border_color($reject)
    }
  }

  &.SECTION_ALL_CONFIRMED,
  &.SECTION_STARTED,
  &.SECTION_ENDED,
  &.SECTION_PROTOCOL_SEND {
    @if($before) {
      &:before {
      @include get_status_border_color($confirm_for_event)
      }
    } @else {
    @include get_status_border_color($confirm_for_event)
    }
  }

  &.not_confirmed {
    @if($before) {
      &:before {
      @include get_status_border_color($not_confirmed)
      }
    } @else {
    @include get_status_border_color($not_confirmed)
    }
  }

  &.confirm {
    @if($before) {
      &:before {
      @include get_status_border_color($confirm)
      }
    } @else {
    @include get_status_border_color($confirm)
    }
  }

  &.ended {
    @if($before) {
      &:before {
      @include get_status_border_color($ended)
      }
    } @else {
    @include get_status_border_color($ended)
    }
  }

  &.finish {
    @if($before) {
      &:before {
      @include get_status_border_color($finish, true)
      }
    } @else {
    @include get_status_border_color($finish, true)
    }
  }

  &.completed {
    @if($before) {
      &:before {
      @include get_status_border_color($completed, true)
      }
    } @else {
    @include get_status_border_color($completed, true)
    }
  }

  &.finished {
    @if($before) {
      &:before {
      @include get_status_border_color($finished, true)
      }
    } @else {
    @include get_status_border_color($finished, true)
    }
  }

  &.storno {
    @if($before) {
      &:before {
        @include get_status_border_color($storno, true)
      }
    } @else {
      @include get_status_border_color($storno, true)
    }
  }

  &.backup {
    @if($before) {
      &:before {
        @include get_status_border_color($backup, true)
      }
    } @else {
      @include get_status_border_color($backup, true)
    }
  }
}

@mixin get_status_border_color($color, $is_white: false) {
  border-color: $color;
}


@import "color";
