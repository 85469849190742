@import "src/assets/scss/base";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.AdminLogin {
}

.Login {
  .fusion-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    //align-items: center;
    background: transparent;

    .login-container {
      margin: 0 30%;
      //border-radius: 8px;
      //box-shadow: 0 10px 10px rgba(0,0,0,0.5);
      //border: 1px solid rgba($primary, 0.1);
      //min-width: 410px;
      //min-height: 300px;
      //display: flex;
      //flex-direction: column;
      //justify-content: center;
      //align-items: center;
      //background: #fff;

      .login-header {
        text-align: left;
      }
      @include media-breakpoint-down(md) {
        margin: 0 auto;
      }

    }

    .login-main {
      display: flex;
      flex-direction: column;
      align-items: center;

      .image-holder {
        max-width: 300px;
        margin: 20px auto;
      }
    }

    form {
      min-width: 300px;
      //max-width: 300px;
      text-align: left;

      .btn {
        font-size: 20px;
      }
    }
  }
}
