.image-holder {
  max-width: 80%;
}

.img-holder {
  max-width: 100%;
  position: relative;

  img {
    max-width: 100%;
    height: auto;
  }

  &.avatar-circle {
    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }

  &.avatar-cubed {
    width: 100px;
    height: 100px;
    background-size: cover;
    border-radius: 20px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50px;
    }
  }

  .delete-holder {
    position: absolute;
    top: 10px;
    right: 10px;

    .btn {
      font-size: 12px;
      color: $danger;
      background: rgba(0,0,0,0.3);
      padding: 7px 10px;
    }
  }
}
