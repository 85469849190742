@import "../../../assets/scss/base";
@import "../../../assets/scss/mixin/mixin";
@import "../../../assets/scss/mixin/color";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.calendar {
  margin: 0 auto;

  .fc-header-toolbar {
    padding-top: 40px;

    @include media-breakpoint-down(md){
      display: block !important;
    }

    .fc-toolbar-chunk {
      display: flex;
      align-items: center;
    }

    .fc-toolbar-title {
      font-size: 22px;
    }

    .fc-button {
      background: transparent;
      color: rgba($primary, 0.3);
      border: 1px solid rgba($primary, 0.3);

      &.fc-prev-button, &.fc-next-button {
        padding: 0 10px 4px 10px;
        border: none;
        color: $black;
        font-size: 12px;
      }

      &:hover, &:active, &:focus {
        background: $secondaryLight;
        color: $white;
        border-color: $secondaryLight;
      }
    }

    .fc-button-group {
      .fc-button {
        border: none;


        &:hover, &:active, &:focus {
          background: $startColor !important;
          color: $white !important;
        }

        &.fc-button-active {
          background: $startColor;
        }
      }
    }
  }

  th {
    border-color: rgba($grey, 0.3)
  }
  td {
    border-color: rgba($grey, 0.3)
  }

  .fc-view {
    background: $white !important;

    @include media-breakpoint-down(md) {
      min-height: 70vh;
    }
  }

  .fc-col-header-cell {
    text-align: start;
    padding: 10px 15px;

    .fc-col-header-cell-cushion {
      text-decoration: none;
      text-transform: uppercase;
      color: $black
    }
  }

  .fc-day {
    .fc-daygrid-day-frame {
      .fc-daygrid-day-top {
        text-align: start;
        flex-direction: row;

        a {
          color: $black;
          text-decoration: none;
          text-transform: uppercase;
        }
      }
    }

    &.fc-day-today {
      background: lighten($grey, 10%) !important;
    }

    &.fc-day-other {

    }
  }

  .fusion-cal-event {
    cursor: pointer;
    background: lighten($grey, 15%);
    border: none;
    padding: 6px 0 6px 10px;
    position: relative;
    border-left: 4px solid $grey;
    border-radius: 0;
    color: $black;
    font-size: 12px;

    &:hover, &:active, &:focus {
      background: $grey;
    }

    /*&:before {
      content: '';
      position: absolute;
      left: 0;
      top: 3px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: #00a0bd;
      border: 1px solid #00a0bd;
    }*/

    @include get_border_status();

    &.operational {
      &.confirmed {
        &:before {
          @include get_status_color($ready_for_process)
        }
      }
    }

    &.SECTION_PROTOCOL_SEND {
      background: $confirm_for_event;
    }

    .fc-event-main {
      color: $primary;
      font-weight: 300;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.own-event {
      .fc-event-main {
        color: $primary;
        font-weight: 700;
      }
    }
  }
}

.event-modal {
  .modal-content {
    border: none;
  }
}
