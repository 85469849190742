@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');


.txt {

  &-el {
    font-weight: 200;
  }
  &-l {
    font-weight: 300;
  }
  &-m {
    font-weight: 500;
  }
  &-sb {
    font-weight: 600;
  }
  &-b {
    font-weight: 700;
  }
  &-eb {
    font-weight: 800;
  }
}
