.status {
  text-align: center;

  background: $white;
  padding: 4px 8px;
  border-radius: 50px;
  border: 1px solid $primary;

  @include get_status;
}


.Toastify {
  &__toast-container {
    top: 65px;
    width: 350px
  }

  &__toast {
    padding: 0;
    position: relative;
  }

  &__toast-body {
    padding: 0;
  }

  &__close-button {
    padding: 8px;
    position: absolute;
    right: 0;
    top: 0;
    display: none;
  }

  .q1-toast {
    //padding: 8px;

    &.info {
      .bd-placeholder-img {
        rect {
          fill: $primary;
        }
      }
    }
  }
}
