.big-logo-info {
  height: 100%;
  font-size: 50px;
  text-align: center;
  color: $white;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  .text {
    color: $white;
    margin: auto;
    padding: 0;
  }

  &-event {
    background: $eventColor;
  }

  &-logistic {
    background: $logisticColor;
  }

}




.RichEditor {
  &-root {
    background: #fff;
    font-size: 14px;
    padding: 0 0 15px 0;
    border: 1px solid rgba($black, 0.1);
  }

  &.editor {
    border-top: 1px solid #ddd;
    cursor: text;
    font-size: 16px;
    margin-top: 10px;

    .public-DraftEditorPlaceholder-root {
      margin: 0 -15px -15px;
      padding: 15px;
    }

    .public-DraftEditor-content {
      margin: 0 -15px -15px;
      padding: 15px;
      min-height: 100px;
    }

    .public-DraftStyleDefault-pre {
      background-color: rgba(0, 0, 0, 0.05);
      font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
      font-size: 16px;
      padding: 20px;
    }
  }
  &-hidePlaceholder {
    .public-DraftEditorPlaceholder-root {
      display: none;
    }
  }

  &-controls {
    font-size: 14px;
    user-select: none;
    display: flex;
  }

  &-styleButton {
    position: relative;
    color: $black;
    cursor: pointer;
    width: auto;
    padding: 5px 20px;
    border: none;
    border-bottom: 1px solid rgba($grey, 0.4);
    display: inline-block;
    text-align: center;
    flex: 1 1 auto;

    &:after {
      position: absolute;
      content: "";
      border-right: 1px solid rgba($grey, 0.4);
      right: 0;
      top: 20%;
      height: 60%;
    }
  }

  &-activeButton {
    background: rgba($secondaryBase, 0.5);
    border-color: $secondaryBase;
    border-width: 3px;
    color: $black;
  }
}

.DraftEditor {
  &-root {
    padding: 10px 15px;
    min-height: 250px;
  }
}

.small {
  font-size: 12px !important;
}

.text-description {
  color: darken($grey, 20%);
  font-weight: 600;
}