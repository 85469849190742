

.card {
  &-header {
    background: $white;

    &.with-action {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .action {
        border-left: 1px solid $grey;
        .btn {
          border-radius: 0 !important;
          background:transparent !important;

          &:hover, &:active, &:focus {
            background: $secondaryBase !important;
          }
        }
      }
    }
  }

  &-transparent {
    background: transparent;

    .card-header {
      background: rgba(0,0,0,0.3);
    }

    * {
      border-color: rgba($white, 0.4)
    }
  }
}
