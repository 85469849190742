@import "../../assets/scss/base";

.list {
  &-container {
    width: 100%;
  }
  &-holder {
    margin: 0 auto;
  }
  &-table {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    max-height: 60vh;
  }

  &-header {
    font-weight: 900;
    font-size: 11px;
    flex: 0 0 auto;
    width: calc(100% - 0.9em);
    display: table;
    table-layout: fixed;
    text-transform: uppercase;
    background: $white;

    .sort-th {
      cursor: pointer;
      min-width: 80px;
    }
  }
  &-body {
    background: $white;
    font-size: 13px;
    flex: 1 1 auto;
    display: block;
    overflow-y: scroll;

    tr {
      width: 100%;
      display: table;
      table-layout: fixed;
    }

    td, th {
      height: 30px;
    }


    h6 {
      font-size: 13px;
      padding-left: 20px;
      margin-bottom: unset;
    }
    h5 {
      font-size: 13px;
      margin-bottom: unset;
      text-transform: uppercase;
    }

    .avatar {
      padding: 10px 30px;

      .img-holder {
        height: 28px;
        width: 28px;

        img {
          border-radius: 25%;
          height: 100%;
          width: auto;
          object-fit: cover;
        }
      }
    }
  }

  &-user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &-image-holder {
    height: 40px;
    width: 40px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
