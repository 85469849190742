.fusion-nav-link {
  color: $white;
  display: flex;
  align-items: center;
  border-radius: 0;
  padding: 25px 20px;
  width: 100%;
  text-decoration: none;

  &:hover, &:active, &:focus {
    background: rgba($secondaryLight, 0.3);
    color: lighten($secondaryLight, 10%) !important;
    text-decoration: none;
  }

  &.active {
    background: rgba($secondaryLight, 0.1);
    color: lighten($secondaryLight, 10%);
    border-left: 5px solid $secondaryLight;
    font-weight: 700;

    &:hover, &:active, &:focus {
      box-shadow: unset;
      color: lighten($secondaryLight, 10%) !important;
      text-decoration: none;
    }
  }

  .icon {
    padding-right: 20px;
  }
}


.fusion-dropdown-link {
  @extend .fusion-nav-link;
  padding: 10px 20px;
}

.fusion-basic-link-icon {
  color: $black;
  padding: 20px 15px;

  .icon {
    color: lighten($secondaryLight, 10%);
    padding-right: 20px;
  }
}

.fusion-nav-icon-link {
  color: $white;
  display: flex;
  align-items: center;
  transition: all 250ms ease-in;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 14px;
  padding: 0.6rem;

  &:hover, &:active, &:focus {
    background: $white;
    color: $black;
  }

  &.active {
    background: $white;
    color: $black;

    &:hover, &:active, &:focus {
      box-shadow: unset;
      color: $black;
    }
  }
}

.fusion-nav-icon-link-filled {
  background: $white;
  color:  $black;
  padding: 0.6rem;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 14px;

  &.active {
    background: $secondaryBase;
  }
}

$count: 15;

//@for $i from 1 through $count {
//  .nav-item {
//    &:nth-child(#{$i}){
//      .fusion-nav-icon-link-filled {
//        @include random-bgr();
//      }
//    }
//  }
//}

.nav-item {
  &.dropdown {
    .fusion-nav-link {
      cursor: pointer;

      &:after {
        position: absolute;
        right: 0;
        opacity: 0;
        transition: right 300ms ease-in;
      }

      &:hover, &:active, &:focus {
        &:after {
          opacity: 1;
          right: 10px;
        }
      }
    }

    &.show {
      .fusion-nav-link {
        &:after {
          opacity: 1;
          right: 10px;
        }
      }
    }
  }
}
.fusion-nav-dropdown-menu {
  background: $primary;
  color: $white;
  top: -10px;
  left: -10px;

  /*
  &:before {
    content: " ";
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    left: -15px;
    top: 4px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right:15px solid $primary;
  }
  */

  .dropdown-item {
    &:hover, &:focus, &:active {
      color: darken($white, 10%);
      background: $black;
    }
  }

  .fusion-dropdown-link {
    color: $white;
    font-weight: 700;
    text-decoration: none;

    &.active {
      color: $white;
      background: $secondary;
    }
  }
}
