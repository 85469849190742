.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.form-control, .form-select {
  border: none;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
}

.form-date-control {
  padding: 0;
  border: none;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;

  * {
    border: none !important;
  }
}

.rs-picker {
  .rs-picker-toggle {
    border: none;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
  }
}

.editable {
  position: relative;

  .edit-button-holder {
    position: absolute;
    right: 0;
    top: 0;

    .edit-button {
      background: $primary;
      color: $white;
      border-radius: 50%;
    }

  }

  &.input-group {
    .btn {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.form-switch {
  .form-check-input {
    &:checked {
      background-color: $secondaryBase;
      border-color: $secondaryBase;
    }
  }
}

.react-datepicker-popper  {
  z-index: 10
}