@import "src/assets/scss/base";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.BaseLayout {
  padding-top: 65px;
  padding-bottom: 15px;
  background: $lightGrey;

  .layout-header {
    padding-bottom: 2em;
  }

  .header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 900;
    background: $white;
    border: 2px solid rgba($grey, 0.5);
    box-shadow: $grey 0px 1px 10px;

    .nav-holder {
      width: 100%;
      /*display: flex;
      justify-content: flex-end;*/

      .button-home-link {
        width: 65px;
        text-align: center;
        color: $black;
        display: flex;
        justify-content: center;
        align-items: center;

        &:focus, &:active, &:hover {
          text-decoration: none;
          background: rgba($white, 0.1);
        }

        h3 {
          padding: 0;
          margin: 0;
        }
      }
    }

    .dropdown {
      &-toggle {
        cursor: pointer;
      }
      .dropdown-menu {
        top: 14px !important;
        //right: -27px !important;
        width: 260px;
        border: none;
        border-radius: 0;
        box-shadow: $grey 0px 10px 10px;

        &:before {
          content: " ";
          width: 0;
          height: 0;
          display: none;
          position: absolute;
          right: 14px;
          top: -12px;
          transform: rotate(90deg);
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-right: 8px solid #fff;
        }
      }
    }

    .avatar-holder {
      width: 45px;
      height: 45px;

      .avatar {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 25%;
      }
      &:after {
        position: absolute;
        right: -15px;
        top: 50%;
        border-top: 0.45em solid;
        border-right: 0.45em solid transparent;
        border-bottom: 0;
        border-left: 0.45em solid transparent;
      }
    }
  }

  .main-area {
    display: flex;
  }

  .sidebar-holder {
    width: 0;
    display: none;

    @include media-breakpoint-up(md){
      width: 240px;
      display: block;
    }
  }

  .main-holder {
    width: calc(100vw - 240px);

    @include media-breakpoint-down(lg) {
      width: 100%;
    }

    .message-holder {
      background: $secondaryBase;
      margin-bottom: 3em;

      .container {
        width: 80%;
        max-width: 1750px;
        padding: 10px 20px;

        a {
          color: $white;
          text-decoration: none;
        }
      }
    }
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: auto;
    height: 100vh;
    border-radius: 0 0 3px 3px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    background: $startColor;
    z-index: 900;

    .icon-bar {
      width: 65px;
      padding-top: 105px;
      padding-bottom: 20px;
      background: $startColor;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    .logo-holder {
      max-width: 240px;
      height: 65px;
      border-bottom: 2px solid rgba($grey, 0.5);
      padding: 1rem 2.5rem;
      img {
        width: 100%;
        height: auto;
      }
    }

    .nav-holder {
      height: 100%;
      display: flex;
      flex-direction: column;
      min-width: 240px;
      //align-items: center;
      //justify-content: space-between;

      .nav-bread-header {
        text-transform: uppercase;
        font-weight: bold;
        color: rgba($white, 0.3);
      }

      .nav-item {
        .collapse {
          color: $white;
          background: rgba($secondaryLight, 0.2);
        }
      }
    }

  }

  .fusion-container {
    border-radius: 5px;
    width: 95%;
    max-width: 1750px;
    padding: 5px 10px 100px 10px;
    min-height: 82vh;
    background: transparent;

    @include media-breakpoint-up(md){
      width: 80%;
    }
  }

  &.BaseLayoutUser {

  }

  &.white {
    background: $white;
  }

  &.w-75 {
  }

}




.task-overview-container {
  width: 100%;
  padding: 15px 0;

  .task-overview-holder {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .task {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 180px;
      justify-content: space-around;
      border-radius: 3px;
      color: $primary;
      border: 1px $grey solid;
      padding: 10px 0;
      transition: all 250ms ease-in;
      cursor: pointer;

      &-icon {
        font-size: 30px;
        text-align: center;
      }

      &-content {
        text-align: center;
      }

      &-heder {
        h3 {
          font-size: 18px;
        }
      }

      &-info {
        h2 {
          font-size: 40px;
          font-weight: 600;
        }
      }

      &:hover, &:focus, &:active {
        background: $danger;
        color: $white;
        border: 7px $white solid;
        box-shadow: 0 30px 60px rgba($danger, 0.09);
      }
    }
  }
}
