@import "../../assets/scss/base";

.btn-add-tag {
  width: 35px;
  height: 35px;
  border-radius: 25% !important;
  font-size: 10px !important;
  padding: 0 !important;
  background-color: $secondaryBase !important;

  &:hover, &:active, &:focus {
    background-color: darken($secondaryBase, 10%) !important;
  }
}

.search {
  &-field {
    &-small {
      position: relative;
    }

    &-list {
      position: absolute;
      background: #fff;
      width: 100%;
      padding: 10px 0;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);

      &-item {
        padding: 5px 15px;
        cursor: pointer;

        &:hover, &:active, &:focus {
          background: #b5b5b5;
        }
      }
    }
  }
}