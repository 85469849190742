@import "../../../assets/scss/base";

.EditUser {
  padding-top: 60px;

  .card {
    .card-header {
      background: $primary;
      color: $white;

      &.with-action {
        .btn-add-tag {
          background: $white !important;
          color: $primary;
          border-bottom-right-radius: 20px !important;
          border-top-right-radius: 20px !important;
        }
      }
    }
  }

  .fusion-container {
    max-width: 1920px;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .user-info {
    .header-info {
      width: 100%;
      height: 250px;
      background-size: cover;
      position: relative;

      .header-container {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0);

        .main-info {

          &-detail {
            * {
              color: $black;
            }
          }
        }
      }
    }

    .main-area-container {
      max-width: 1450px;
    }
  }

  .main-info {
    width: 100%;
    padding-top: 67px;

    &-row {
      max-width: 92%;
      margin: 0 auto;

      .col-md-5 {
        padding: 0;
      }
    }

    &-header {
      font-size: 18px;
      font-weight: 700;
    }

    &-calendar {
      font-size: 13px;
      font-weight: 400;
    }

    &-list {
      .list-group-item {
        border: none;
        padding: 0.3rem 0;
        font-size: 13px;
      }
    }
  }

  .user-detail {
    &-container {
      padding-top: 67px;
      max-width: 92%;
      margin: 0 auto;
    }

    &-header {
      font-size: 18px;
      font-weight: 700;
      width: 100%;
      border-bottom: 1px solid gray;

      &-additional {
        font-size: 13px;
        font-weight: 700;
      }
    }

    &-tag-list {
      padding: 0;
    }

    &-list {
      .list-group-item {
        border: none;
        padding: 0.3rem 0;
        font-size: 13px;
      }
    }
  }

  .info-table {
    font-size: 13px;

    .table-header {
      font-weight: 900;
    }
  }

  .btn-remove {
    font-size: 10px !important;
    margin: 5px;
    padding: 0;
    width: 30px;
    height: 30px;
    color: white;
    border-radius: 25%;
  }



}
