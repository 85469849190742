@import "src/assets/scss/base";

.NoLayout {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #fdfbec;

  .background-holder {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .fusion-container {
    min-height: 80vh;
    z-index: 1;
  }
}
