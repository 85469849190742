@import "../../assets/scss/base";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.header {
  .header-image-holder {
    max-width: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: auto;
    }
  }

  &-bar {
    padding-left: 240px;
    width: 100%;

    @include media-breakpoint-down(md) {
      padding-left: 5px;
    }

    .container {
      max-width: 1750px;
    }
  }

  .fusion-header {
    h5 {
      font-size: 14px;
      margin-bottom: 0.2em;
    }
    h6 {
      font-size: 10px;
      margin-bottom: 0;
    }
  }
}

.nav{
  &-icon-overlay {
    position: absolute;
    left: 6px;
    top: 8px;

    .number-message {
      font-size: 8px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 1px solid $secondaryLight;
      background: $secondaryLight;
      color: $white;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }

  .message-link {
    color: $black;
    border-radius: 10px;
    padding-top: 12px;

    &.active {
      background: rgba(0,0,0,0.3);
    }
  }
}

.message {
  &-header {
    padding: 1.25rem;

    h6 {
      font-size: 14px;
      color: rgba($white, 0.4);
    }
    h2 {
      font-size: 28px;
      font-weight: 400;
    }
  }

  &-content {


  }

  &-menuholder {
    overflow-x: hidden;
    color: $white;
  }

  &-menu {
    position: fixed;
    right: 0;
    transform: translate3d(400px, 0, 0);
    top: 65px;
    height: calc(100vh - 65px);
    background: darken($secondaryLight, 20%);
    border-left: 1px solid rgba(0, 0, 0, 0.25);
    transition: transform 300ms ease-in;
    width: 400px;
    max-width: 95%;

    &.open {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.25);
    }

    .card {
      * {
        color: $white;
      }
    }

    .card-body {
      padding: 0 !important;
    }

  }

  &-row-holder {
    a {
      color: $black;

      &:hover, &:active, &:focus {
        color: $black;

        .message-row {
          background: rgba(#000, 0.3);
        }
      }
    }
  }

  &-row {
    min-width: 350px;
    padding: 1.25rem 1rem 10px 1.5rem;
    justify-content: space-between;
    border-bottom: 1px solid rgba($grey, 0.4);

    h6 {
      font-size: 13px;
    }

    p {
      font-size: 11px;
    }

    &-holder {
      max-height: calc(100vh - 250px);
      overflow-y: auto;
      .messages {
        height: 100%;

        .message {
          .row {
            &.unread {
              h6 {
                position: relative;

                &:before {
                  content: '';
                  position: absolute;
                  left: -0.8rem;
                  top: 3px;
                  height: 8px;
                  width: 8px;
                  background: $success;
                  border-radius: 50%;
                }
              }
            }
          }
        }

      }
    }

    &:hover, &:active, &:focus {
      background: rgba(#000, 0.3);
    }
  }
}

.nav-user-details {
  margin: auto;
  color: $black;
  padding: 0 10px;
  font-size: 12px;
}

.fusion-nav-holder {
  .button-home-link {
    .logo-header {
      height: auto;
      width: 100%;
    }
  }

  .header-buttons {
    button {
      color: $black;
      font-size: 12px;

      &:focus, &:active, &:hover {
        color: $black !important;
        text-decoration: none;
        background: rgba($black, 0.1);
      }
    }
  }
  .searchbar {
    .search {
      &-container {
        padding-top: 0;
      }
      &-field {
        color: $black;
        input[type="text"]{
          border: 1px solid transparent;
          background: transparent;
          color: $black;
          min-height: 40px;
          font-weight: 200;
          font-size: 16px;
          transition: border ease-in 200ms;

          &::placeholder {
            color: $black;
          }

          &:focus {
            border: 1px solid $white !important;
          }
        }
        .input-group-append {
          button {
            border: none;
            border-left: 1px solid $white !important;
            color: $black;

            &:active, &:focus, &:hover {
              background: $white !important;
              color: $primary;
            }
          }
        }
      }
    }
    &-calendar-list {
      position: fixed;
      width: calc(100% - 63px);
      right: 15px;
      top: 65px;
      border-radius: 0 0 5px 5px;
      //border: 1px solid rgba(#010101, 0.3);
      box-shadow: 5px 6px 10px rgba(#010101, 0.3);
      background: #fff;
      max-height: 80vh;
      height: 100vh;
      overflow-x: auto;
      padding: 30px 10px;
      display: none;

      @include media-breakpoint-down(md){
        left: 0;
        width: 100%
      }

      &.searchbar-show {
        display: flex;
      }

      .task-list {
        h4 {
          text-align: center;
          font-weight: 800;
        }

        .task {
          box-shadow: none;
          border: 1px solid rgba($primary, 0.1);
          padding: 15px 35px;
        }
      }
    }
  }
}

.searchbar-backdrop {
  cursor: pointer;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  z-index: 950;
  overflow: hidden;
}
