table {

  th {
    &.btn-th {
      width: 55px;
    }
    &.check-th {
      width: 35px;
    }
  }

  td {
    &.btn-td {
      width: 55px;

      .btn {
        padding: unset;
      }
    }
  }
}

.table-responsive {
  table {
    min-width: 800px;
  }
}
