@import "src/assets/scss/base";

.MailLayout {
  .fusion-container {

  }
}

.mail {
  display: flex;

  &-header {
    margin-top: 0 !important;
    margin-bottom: 2em;
    background: $white;

    .bg-white {
      padding: 0;
      border: 1px solid  rgba(#000, 0.1);
    }

    .btn-outline-primary {
      color: $secondary;
      border-radius: 0;
      border: none;
      border-right: 1px solid rgba(#000, 0.2);

      &:last-child {
        border-right: none;
      }

      p {
        margin: 0;
      }
    }

  }

  &-sidebar {

    &-inboxes {
      list-style-type: none;
      padding: 0;

      li {
        padding: 10px 15px;
        cursor: pointer;

        a {
          font-weight: bold;

          .item-count {
            padding-left: 15px;
          }
        }

        &:hover, &:active, &:focus, &.active {
          background: #cfcfcf;
        }
      }
    }
  }

  .inbox-container {
    height: 75vh;

    .email{
      &-list {
        overflow-y: scroll;
        height: 100%;
        background: $white;
        padding: 20px 0;
      }

      &-prediction {
        position: absolute;
        z-index: 999;
        max-height: 50vh;
        overflow: auto;
      }

      &-item {
        padding: 10px 20px;
        border-bottom: 1px solid $primary;
        cursor: pointer;

        &:hover, &:active, &:focus {
          background: lighten($primary, 70%);
        }

        &.selected {
          background: lighten($tertiary, 50%);

          &:hover, &:active, &:focus {
            background: #fff
          }
        }
      }

      &-content {
        max-height: 100%;
        background: $white;
        padding: 30px 20px;

        &-message {
          max-height: 100%;
          overflow-y: auto;
        }
      }
    }
  }
}
