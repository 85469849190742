@import "src/assets/scss/base";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.TwoColumnLayout {
  min-height: 100vh;

  .background-holder {
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(md) {
      padding: 30px 50px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .fusion-container {
    min-height: 80vh;
    z-index: 1;
    max-width: unset;
    width: 66.6666666%;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}
