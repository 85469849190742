.task {
  margin: 0;
  max-height: 250px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #cbcbcb;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.09);
  padding: 16px 35px;
  transition: all 250ms ease;
  //cursor: pointer;

  /*&-headline {
    align-items: center;
  }*/

  &-icon {
    font-size: 30px;
    margin-top: -9px;
  }

  &-info {
    h2 {
      font-size: 24px;
      font-weight: 700;
    }
    h3 {
      font-size: 16px;
    }
    h5 {
      font-size: 14px;
    }
    p {
      font-size: 12px;
    }
  }

  &-head {
    h3 {
      font-size: 16px;
    }
  }

  &-status {
    .status {
      text-align: center;
      background: $white;
      padding: 7px 0;
      border-radius: 3px;
      color: $white;

      &.danger {
        background: $danger;
      }

      &.success {
        background: $success;
      }

      @include get_status;

      h3 {
        font-size: 12px;
        font-weight: 900;
        margin: 0;
      }
    }
  }

  &-title {
    h2 {
      font-size: 16px;
      font-weight: 900;
    }
  }
  &-user {
    h5 {
      font-size: 11px;
      font-weight: 600;
    }
  }
  &-content {
    p {
      display: -webkit-box;
      font-size: 11px;
      font-weight: 600;
      color: $tertiaryText;
      max-height: 90px;
      overflow: hidden;
      word-wrap: break-word;
      text-overflow: ellipsis;
      -webkit-line-clamp: 5; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }
}
