
@mixin get_status($before: false){
  &.created {
    @if($before) {
      &:before {
        @include get_status_color($created)
      }
    } @else {
      @include get_status_color($created)

    }
  }

  &.active {
    @if($before) {
      &:before {
        @include get_status_color($active)
      }
    } @else {
      @include get_status_color($active)

    }
  }

  &.candidature {
    @if($before) {
      &:before {
        @include get_status_color($candidature, true)

      }
    } @else {
      @include get_status_color($candidature, true)


    }
  }

  &.not_confirmed {
    @if($before) {
      &:before {
        @include get_status_color($not_confirmed)

      }
    } @else {
      @include get_status_color($not_confirmed)

    }
  }

  &.operational {
    @if($before) {
      &:before {
        @include get_status_color($operational)

      }
    } @else {
      @include get_status_color($operational)

    }
  }

  &.not_apply {
    @if($before) {
      &:before {
      @include get_status_color($not_apply, true)
      }
    } @else {
    @include get_status_color($not_apply, true)
    }
  }

  &.apply {
    @if($before) {
      &:before {
        @include get_status_color($apply)
      }
    } @else {
      @include get_status_color($apply)
    }
  }

  &.reject {
    @if($before) {
      &:before {
      @include get_status_color($reject)
      }
    } @else {
    @include get_status_color($reject)
    }
  }

  &.confirm_for_event {
    @if($before) {
      &:before {
      @include get_status_color($confirm_for_event)
      }
    } @else {
    @include get_status_color($confirm_for_event)
    }
  }

  &.not_confirmed {
    @if($before) {
      &:before {
      @include get_status_color($not_confirmed)
      }
    } @else {
    @include get_status_color($not_confirmed)
    }
  }

  &.confirm {
    @if($before) {
      &:before {
      @include get_status_color($confirm)
      }
    } @else {
    @include get_status_color($confirm)
    }
  }

  &.ended {
    @if($before) {
      &:before {
      @include get_status_color($ended)
      }
    } @else {
    @include get_status_color($ended)
    }
  }

  &.finish {
    @if($before) {
      &:before {
      @include get_status_color($finish, true)
      }
    } @else {
    @include get_status_color($finish, true)
    }
  }

  &.completed {
    @if($before) {
      &:before {
      @include get_status_color($completed, true)
      }
    } @else {
    @include get_status_color($completed, true)
    }
  }

  &.finished {
    @if($before) {
      &:before {
      @include get_status_color($finished, true)
      }
    } @else {
    @include get_status_color($finished, true)
    }
  }

  &.storno {
    @if($before) {
      &:before {
        @include get_status_color($storno, true)
      }
    } @else {
      @include get_status_color($storno, true)
    }
  }

  &.backup {
    @if($before) {
      &:before {
        @include get_status_color($backup, true)
      }
    } @else {
      @include get_status_color($backup, true)
    }
  }
}

@mixin get_status_color($color, $is_white: false) {
  background: $color;
  border-color: $color;
  @if ($is_white) {
    color: $white;
  } @else {
    color: $black;
  }
}


@import "color";
