.fade-in {
  animation: fadeIn ease 0.2s;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}


.fade-height {
  animation: fadeHeight ease-in 0.8s;
}

@keyframes fadeHeight {
  0% {height:0;}
  100% {height:100%;}
}
