// COLOR SECTION
$primary: #101e1e;
$secondaryBase: #fac400;
$secondary: #110729;
$secondaryLight: #0079C4;
$tertiary: #101e1e;

$success: #30ba85;
$success_light: #b1ffdf;
$ready_for_process: #00bb1f;
$danger: #ba304f;
$danger_light: #ca657f;

$primaryText: #3B3434;
$secondaryText: #707070;
$tertiaryText: #656565;

$white: #ffffff;
$black: #010101;
$grey: #CBCBCB;
$lightGrey: lighten($grey, 18%);

$eventColor: #FF1493;
$logisticColor: #1E90FF;

// Event User Section
$created: #AEAAAA;
$active: $success;
//$candidature: #66FFFF;
$candidature: $secondaryBase;
$not_apply: #99CCFF;
$apply: #FAC400;
$reject: #FF0000;
$confirm_for_event: #30BA85;
$not_confirmed: #CA657F;
$operational: #CCFF66;
$confirm: #47FD00;
$ended: $secondaryLight;
$finish: #CC66FF;
$storno: #CC0000;
$completed: $tertiary;
$finished: $tertiary;
$backup: #08e0ea;

  // BACKGROUND BASE
$startColor: $secondary;
$endColor: $secondaryBase;


#root {
  min-height: 100vh;
  //background: linear-gradient(360deg, rgba($startColor,1) 0%, rgba($startColor,1) 5%, rgba($endColor,0.5) 30%, rgba($white,1) 50%, rgba($white,1) 100%);
}

html, body {
  font-family: Heebo, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.medium {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.clickable {
  cursor: pointer;
}


@import "base/transition";
