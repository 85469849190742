@import "../../assets/scss/_base.scss";


.dashboard-accordion {
  .accordion-header {
    &.confirmed {
      button {
        background-color: $confirm_for_event;
      }
    }
    &.applied {
      button {
        background-color: orange;
      }
    }
    &.open {
      button {
        background-color: $apply;
      }
    }
    &.old {
      button {
        background-color: $grey;
      }
    }
  }
}