

.dropzone-row {
  .img-holder {
    height: 100px;
    overflow: hidden;

    img {
      height: auto;
      width: 100%;
    }

    .delete-holder {
      .btn {
        background: #ba304f;
        color: #fff;
      }
    }
  }
}
