.fusion-container {
  width: 100%;
  max-width: 830px;
  padding-left: 15px;
  padding-right: 15px;
  background: $white;
  min-height: 50vh;
}

.standard-container {
  width: 80%;
  max-width: 1750px;
}

.overlay-container {
  position: relative !important;
}

.small-row {
  * {
    font-size: 0.9em !important;
  }
}

.row {
  &.col-mb-2 {
    > * {
      margin-bottom: 0.5em;
    }
  }
}

/*.fusion-form-row  {
  .form-group {
    margin-bottom: 0;
  }
}*/
