.btn {
  border-radius: 3px;
  padding-left: 25px;
  padding-right: 25px;

  &-primary {
    background: $primary;
    border-color: $primary;

    &:hover, &:active, &:focus {
      background: darken($primary, 20%) !important;
      border-color: darken($primary, 20%) !important;
    }
  }

  &-secondary {
    background: $secondaryBase;
    border-color: $secondaryBase;

    &:hover, &:active, &:focus {
      background: darken($secondaryBase, 20%) !important;
      border-color: darken($secondaryBase, 20%) !important;
    }
  }

  &-secondaryDark {
    background: $secondary;
    border-color: $secondary;
    color: $white;

    &:hover, &:active, &:focus {
      background: darken($secondary, 20%) !important;
      border-color: darken($secondary, 20%) !important;
    }
  }

  &-link {
    background: transparent;
    border-color: transparent;
    color: $primaryText;

    &:hover, &:active, &:focus {
      color: darken($primaryText, 20%) !important;
    }
  }

  &-remove {
    background: $danger;
    border-color: $danger;

    &:hover, &:active, &:focus {
      background: darken($danger, 20%) !important;
      border-color: darken($danger, 20%) !important;
    }
  }

  &-outline {

    &-primary {
      background: $white;
      border-color: $primary;
      color: $primary;

      &:hover, &:active, &:focus {
        background: $primary !important;
        border-color: $primary !important;
      }
    }

    &-secondary {
      background: $white;
      border-color: $secondaryBase;
      color: $secondaryBase;

      &:hover, &:active, &:focus {
        background: $secondaryBase !important;
        border-color: $secondaryBase !important;
      }
    }

    &-info {
      background: $white;
      border-color: $grey;
      color: $grey;

      &:hover, &:active, &:focus {
        background: $grey !important;
        border-color: $grey !important;
      }
    }
  }

  &-select {
    border: 1px solid $primary;
    border-radius: 3px;
    > div {
      border-radius: 3px;
    }
  }

  &-color-white {
    color: $white;

    &:hover, &:active, &:focus {
      background: $primary !important;
      border-color: $primary !important;
    }
  }

  &.add-button {
    font-size: 12px;
    font-weight: 500;
    border-radius: 50px;
  }

  &-small {
    font-size: 14px;
    padding: 0.3rem 1.3rem;
  }

  &:disabled {
    background: $grey;
    border-color: $grey;
    color: $black;
  }
}
