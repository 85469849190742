@import "src/assets/scss/base";

.event {
  &-title-content {
    padding-top: 10px;
    padding-bottom: 10px;
    background: $lightGrey;

    .form-control {
      background: none;
      border: none;
      color: $black;
      font-size: 18px;
      font-weight: 900;

      &:active, &:focus, &:hover {
        background: none !important;
        border: none !important;
      }
    }
  }

  &-create-container {
    width: 100%;

    header {
      //text-align: center;

      h3 {
        font-weight: 700;
      }
    }

    input, select {
      font-weight: 600;
      font-size: 18px;
    }

    textarea {
      font-weight: 600;
      font-size: 18px;

      min-height: 100px;
    }

    .rs-picker-toggle {
      * {
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

  &-main-content {
    max-width: 700px;
    padding: 2% 0;

    .list-max-height {
      max-height: 120px;
      overflow-y: auto;
    }

    p {
      margin: 0;
      padding: 0;
      color: $secondaryText;
    }
    h3 {
      margin: 0;
      padding: 0;
    }
  }

  &-send-container {
    &.align-items-center {
      .form-group {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  &-date {
    border: 1px solid rgba($primary, 0.1);
    border-radius: 5px;
    padding: 20px 0;
    margin-bottom: 3%;

    &:first-child {
      .event-date-delete {
        display: none;
      }
    }

    &-info-header {
      font-weight: 900;
    }

    .rs-picker-date {
      width: 100%;
    }

    .google-places-autocomplete {
      position: relative;

      .suggestions-container {
        position: absolute;
        z-index: 999;
        background: $white;
        border: 1px solid rgba($primary, 0.1);
        box-shadow: 0 6px 10px rgba($primary, 0.3);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding-bottom: 10px;

        .suggestion {
          padding: 5px 10px;
          font-size: 16px;
          font-weight: 700;
          cursor: pointer;
          border-bottom: 1px solid rgba($primary, 0.1);

          &:hover, &:active, &:focus {
            background: rgba($primary, 0.1);
          }
        }
      }
    }
  }

  &-info {
    &-content {
      .event-date {
        padding: 10px 20px;
      }
    }
  }

  &-infos {
    .event-add {
      display: none;
    }

    &-empty {
      .event-delete {
        display: none;
      }
      .event-add {
        display: block;
      }
    }
  }

  &-delete {
    width: 35px;
    height: 35px;
    padding: 0 !important;
    border-radius: 50% !important;
  }

  &-add {
    width: 35px;
    height: 35px;
    padding: 0 !important;
    border-radius: 50% !important;
  }

  &-feedback {
    .feedback-card {
      padding: 10px 15px;
    }
  }
}

.rs-picker-menu {
  z-index: 1090;

  &.fade.in {
    opacity: 1 !important;
  }
}


.EventDetail {
  .status {
    font-size: 12px;
  }

  .user-assigned-status {
    span {
      padding-left: 1em;
    }
  }
}

.divider-event {
  background-color: rgba($black, 0.4) !important;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}