.UserListModal {
  .search-modal {
    .search {
      &-container {
        padding-top: 0;
        padding-bottom: 20px;
      }
      &-field {
        max-width: 100%;

        input {
          padding: 10px 12px;
          min-height: 50px;
        }
      }
    }
  }

  .modal-body {
    max-height: 70vh;

    .user-list-container {
      max-height: 530px;
      height: 100%;
      overflow-x: auto;
    }
  }
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}

.modal {
  .modal-content {
    .modal-header {
      &.background-black {
        background-color: $black;
        border-bottom: 1px solid $black;
        color: $white;
      }
    }
  }
}